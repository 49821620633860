html
{
  overflow:visible;
  background-color:#000;
}
body
{
  overflow:visible;
}
@font-face {
  font-family: "Icons";
  src: url('../font/icons.ttf');
}
#moduledesc {

}

.profleft
{
  display:inline-block;
  width:12vw;
  vertical-align:middle;
}
.profright
{
  display:inline-block;
  vertical-align:middle;
  /*margin-top:2vh;*/
  text-align: left;
}
.profright b
{
  font-weight:bold;
}
.profpseudo
{
  margin-left:5vw;
  font-size:4vh;
  font-weight:bold;
}
.profstats
{
  margin-left:3vw;
  margin-top:5vh;
  margin-bottom:3vh;
  line-height: 2.8vh;
}
.profphrase
{
  margin-left:5vw;
  line-height: 2.8vh;
  width:92vh;
}
.profstats li
{
  margin-left: 2vw;
  margin-right: 2vw;
  margin-bottom:0;
  height:auto;
  display:inline-block;
}
.profavatar
{
  display:inline-block;
  width:9vw;
  height:9vw;

  & + a {
    display: block;
    margin: 10px auto;
  }
}

.profleft__logout {
  a {
    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 21px;
      background-image: url("../../img/account/logout.svg");
      background-repeat: no-repeat;
      background-position: center;
      vertical-align: sub;
      margin-right: 3px;
    }
  }
}
.profavatar p {
  font-size: 4.5vw;
  margin-top: 2.35vw;
}
.profdesabo
{
  text-decoration:none;
  display:block;
  font-size:1.8vh;
  color: #cdcdcd;
}
.profdesabo:hover
{
  color: #b3b3b3;
}
#icondelete
{
  font-family: Icons;
  font-size:1.5vh;
  font-weight: bold;
}
.mfollowfalse
{
  display:inline-block;
  color: black;
  background-color: #efefef;
  /* margin-top: 7vh; */
  text-decoration: none;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;

  width: 9vw;
  /* margin-left: 5vh; */
  text-align: center;
  margin-bottom:1.5vh;
  margin-top:1vh;
}
.mfollowfalse:hover
{
  color:black;
  background-color:#bbb;
}
.mfollowtrue
{
  display:inline-block;
  color: black;
  background-color: #efefef;
  /* margin-top: 7vh; */
  text-decoration: none;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;

  opacity:0.2;
  width: 9vw;
  /* margin-left: 5vh; */
  text-align: center;
  margin-bottom:1.5vh;
  margin-top:1vh;
}
.mfollowtrue:hover
{
  color:black;
}
#entete
{
  display:block;
  margin-top:4vh;
}

#filmbg
{
  margin-bottom:5vh;
}
#statscat
{
  margin-left:5vw;
  margin-right:5vw;
  margin-top: 9vh;
  margin-bottom:6vh;
  color:#cdcdcd;
}
#statscat p
{
  display:inline-block;
  margin-right: 4vh;
}
.changecat
{
  margin-left:0.5vh;
  margin-right:0.5vh;
  color:#cdcdcd;
}
.changecat:hover
{
  color:#b3b3b3;
}
.showfilms
{
  display:block;
  width:100%;
}
.showmembers
{
  width: 80vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.failsearch
{
  font-size: 2.7vh;
}
h3
{
  display:block;
  margin-left:auto;
  margin-right:auto;
  margin-bottom:7vh;
  font-size:3.5vh;
}
.membre_lastco{
  font-size:1.8vh;
  color:#cdcdcd;
  margin-left:5vw;
  margin-top:1vh;
}
@media only screen and (orientation : portrait) {
  #moduledesc {
    margin-top: 2vh;
    line-height: 2.3vh;
    font-size: 2vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vh;
    display: block;
    color: #cdcdcd;
  }
  #entete
  {
    display:block;
    margin-top:0;
  }
  .profleft {
    display: block;
    width: auto;
    /* vertical-align: middle; */
    margin-top: 4vh;
    margin-bottom: 8vh;
  }
  .profavatar {
    display: inline-block;
    width: 25vw;
    height: 25vw;
    margin-bottom: 1vh;
  }
  .profavatar p {
    font-size: 12.5vw;
    margin-top: 5.2vw;
  }
  .mfollowfalse {
    display: block;
    color: black;
    background-color: #efefef;
    /* margin-top: 7vh; */
    text-decoration: none;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    width: 25vw;
    /* margin-left: 5vh; */
    text-align: center;
    margin-bottom: 1.5vh;
    margin-left: auto;
    margin-right: auto;
  }
  .mfollowtrue {
    display: block;
    color: black;
    background-color: #efefef;
    /* margin-top: 7vh; */
    text-decoration: none;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;
    width: 25vw;
    /* margin-left: 5vh; */
    text-align: center;
    margin-bottom: 1.5vh;
    margin-left: auto;
    margin-right: auto;
  }
  .profright {
    display: block;
    /* vertical-align: middle; */
    text-align: center;
  }
  .profpseudo
  {
    margin-left: 8vw;
    margin-right: 8vw;
    line-height: 5vh;
  }
  .membre_lastco{
    margin-left:8vw;
    margin-right:8vw;
  }
  .profstats
  {
    margin-left: 8vw;
    margin-right: 8vw;
  }
  .profphrase
  {
    margin-left: 8vw;
    margin-right: 8vw;
    width:auto;
  }
}
@media only screen and (max-device-width:700px) and (orientation : portrait) {
  .profavatar {
    display: inline-block;
    width: 40vw;
    height: 40vw;
    margin-bottom: 1vh;
  }
  #moduledesc
  {
    margin-top: 2vh;
    line-height: 2.6vh;
    font-size: 2.2vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3vh;
    display: block;
    color: #cdcdcd;
  }
  .profavatar p {
    font-size: 18.5vw;
    margin-top: 9.5vw;
  }
  .mfollowfalse
  {
    width:40vw;
  }
  .mfollowtrue
  {
    width:40vw;
  }
  #statscat p
  {
    display:block;
    margin-right: 0;
    margin-bottom: 1.5vh;
  }
  .profstats li {
    margin-left: 2vw;
    margin-right: 2vw;
    margin-bottom: 1.2vh;
    height: auto;
  }
  .changecat
  {
    line-height: 2.5vh;
  }
}
