body.cookies {
  padding:3vh;
  margin-top: 0;

  .well{
    text-align: left;
    margin-top: 5vh;
    /* padding: 0 7vh 0 7vh; */
    max-width: 1200px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    line-height: 2.9vh;
    padding: 5vh;
    border-radius: 1vh;
    box-shadow: 0vh 0vh 3vh rgba(255, 255, 255, .4);
    background-color: rgba(0, 0, 0, .8);
  }
  a{
    color:#db242c;
  }
  a:hover{
    color:#ae0008;
  }
  .gohome{
    margin-top: 2vh;
    display:inline-block;
  }
  h2{
    font-size: 7vh;
    margin-bottom: 5vh;
  }
  h3{
    font-size: 4vh;
    width: auto;
    margin: 4vh 0 3vh 0;
    line-height: 5vh;
  }
  ul{
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    font-style:italic;
  }

}
