$font_family_1: 'Roboto', sans-serif;
$background_color_1: #000;
$background_color_2: #cccccc;

html {
  overflow: visible;
  background-color: $background_color_1;
}
body.ajoutfilm {
  overflow: visible;
  height: 100%;

  b {
    font-weight: bold;
  }
  #moduledesc {
    padding-top: 4vh;
    line-height: 3vh;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 8vh;
  }
  #filmbg {
    margin-bottom: 5vh;
  }
  .failsearch {
    margin-top: 20vh;
    margin-bottom: 30vh;
    font-size: 2.7vh;
    margin-left: 5vw;
    margin-right: 5vw;
  }
  h3 {
    display: inline-block;
    width: 50vh;
    font-weight: bold;
    font-size: 2.7vh;
    line-height: 3vh;
    margin-top: 1.5vh;
    margin-bottom: 2.5vh;
  }
  label {
    margin-bottom: 2vh;
    display: block;
    margin-top: 5vh;
  }
  .champtext {
    width: 51vw;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    &:focus {
      background-color: $background_color_2;
      transition: 0.2s;
    }
  }
  .champleft {
    display: inline-block;
    margin-right: 1.5vw;
  }
  .champright {
    display: inline-block;
    margin-left: 1.5vw;
    .champtext {
      width: 22.5vw;
    }
  }
  .champchoice {
    width: 25.5vw;
    border-radius: 0vh;
    border: 0;
    height: 4.7vh;
    font-size: 2.15vh;
    &:focus {
      background-color: $background_color_2;
      transition: 0.2s;
    }
  }
  #submit {
    width: 40vh;
    display: block;
    margin-top: 8vh;
  }
  textarea {
    width: 51vw;
    resize: none;
    height: 13vh;
    font-family: $font_family_1;
    font-size: 2.15vh;
    text-align: left;
    border-radius: 0;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    padding-top: 1vh;
    padding-bottom: 1vh;
    line-height: 3vh;
    &:focus {
      background-color: $background_color_2;
      transition: 0.2s;
    }
  }
  input {
    display: inline-block;
  }
  #formajout {
    width: 90vw;
    text-align: center;
  }
  .lastchamp {
    margin-bottom: 3vh;
  }
  @media only screen and (orientation : portrait) {
    .failsearch {
      margin-top: 10vh;
      margin-bottom: 8vh;
      font-size: 2.7vh;
      margin-left: 5vw;
      line-height: 3.5vh;
      margin-right: 5vw;
    }
  }
  @media only screen and (max-device-width: 1400px) and (min-device-width: 768px) and (orientation: portrait) {
    .champtext {
      width: 87vw;
    }
    .champleft {
      display: block;
      margin-right: 0vw;
    }
    .champright {
      display: block;
      margin-left: 0vw;
      .champtext {
        width: 87vw;
      }
    }
    .champchoice {
      width: 87vw;
    }
    textarea {
      width: 87vw;
    }
  }
  @media only screen and (max-width:900px) {
    .champtext {
      width: 67vw;
    }
    .champleft {
      display: block;
      margin-right: 0vw;
    }
    .champright {
      display: block;
      margin-left: 0vw;
      .champtext {
        width: 67vw;
      }
    }
    .champchoice {
      width: 70vw;
    }
    textarea {
      width: 67vw;
    }
    #submit {
      width: 70vw;
    }
  }
  @media only screen and (max-device-width:700px) {
    .champtext {
      width: 78vw;
    }
    .champleft {
      display: block;
      margin-right: 0vw;
    }
    .champright {
      display: block;
      margin-left: 0vw;
      .champtext {
        width: 78vw;
      }
    }
    .champchoice {
      width: 81vw;
    }
    textarea {
      width: 78vw;
    }
    #submit {
      width: 81vw;
    }
  }
  @media only screen and (max-device-width : 750px) {
    .failsearch {
      margin-top: 9vh;
      margin-bottom: 3vh;
      font-size: 2.15vh;
      line-height: 3vh;
      font-weight: bold;
    }
  }
}
