html
{
  width:auto;
  overflow:auto;
  background-color:#000;
 // height: 100%;
}
body
{
  width:auto;
  text-align:center;
  font-family:'Roboto', sans-serif;
  color:white;
  font-size:1.6rem;
  height:100%;
  overflow:auto;
}

/*todo*/
.main_content {
  // min-height:100%;

  min-height: 70vh;

  margin:0 auto;

  position:relative;
}

@font-face {
  font-family: "Folloween";
  src: url('../fonts/Folloween.ttf');
}
#concept
{
  display:inline-block;
  width:90%;
  margin-top:9.5vh;
  font-weight:bold;
}
#filmbg
{
  display:inline-block;
  width:90%;
  margin-top:12.5vh;
  line-height:3vh;
}


h1
{
  font-family: "Folloween" !important;
  color:white;
  font-size:11vh;
  margin-top:2.5vw;
}
.lef
{
  color:#ae0008;
  display:inline;
}
a
{
  color:white;
}
a:hover
{
  color:#bababa;
  transition:0.2s;
}
h3
{
  display:inline-block;
  width:50vh;
  line-height:3vh;
}
form
{
  display:block;
  width:40vh;
  margin-left:auto;
  margin-right:auto;
  margin-top:1vh;
}
form a
{
  text-align:center;
  text-decoration:underline;
  line-height:3vh;
  color:white;
}
input
{
  display:block;
  width:100%;
  border:none;
 // height:5vh;
  margin-bottom:1vh;
  font-family:'Roboto', sans-serif;
  font-size:2.15vh;
  text-align:center;
  border-radius:0;
}
.champ:focus
{
  background-color:#cccccc;
  transition:0.2s;
}
#submit
{
  margin-left:auto;
  margin-right:auto;
  margin-top:1.5vh;
  border-radius:0;
}
#submit:hover
{
  background-color:#bababa;
  transition:0.2s;
}
#sign
{
  font-size:1.7vh;
  margin-top:2vh;
}
#reg
{
  display:block;
  width:100%;
  height:100%;
  background-color:black;
  position:fixed;
  left:0;
  top:0;
  display:none;
  opacity:0;
}

#title
{
  text-align:left;
  margin-top:5vh;
  font-size:6vh;
  padding-left:5vw;
}
#reg label
{
  display:inline-block;
  margin-right:2.5vh;
}
#reg .champ
{
  display:inline-block;
  width:40vh;
  margin-right:8vh;
}
#reg form
{
  width:100%;
  margin-top:10vh;
}
#captcha
{
  display:block;
  text-align:center;
  margin-top:5vh;
  margin-bottom:5vh;
}
.g-recaptcha
{
  display:inline-block;
}
#reg #insc
{
  display:block;
  width:40vh;
  margin-left:auto;
  margin-right:auto;
  margin-top:7vh;
  margin-bottom:22vh;
  border-radius:0;
}
#reg #insc:hover
{
  background-color:#bababa;
  transition:0.2s;
}
#reg a
{
  font-size:2.75vh;
  text-decoration:none;
}
#reg #penny
{
  display:block;
  width:35vw;
  position:fixed;
  bottom:0;
  right:0;
  z-index:0;
}
#verifmdp
{
  display:none;
  background-color:black;
  border-bottom:solid 0.5vh white;
  border-top:solid 0.5vh white;
  width:100%;
  height:40vh;
  position:absolute;
  top:30vh;
  z-index:3;
  left:0;
  text-align:center;
  opacity:0;
}
#verifmdp p
{
  font-size:2.8vh;
  margin-top:4.5vh;
  margin-bottom:5vh;
}
#verifmdp #confmdp
{
  display:inline-block;
  margin-right:0;
  margin-bottom:1.5vh;
}
#inscfin
{
  display:none;
  width:40vh;
  margin-left:auto;
  margin-right:auto;
  margin-top:0;
  opacity:0;
  border-radius:0;

}
#inscfin:hover
{
  background-color:#bababa;
  transition:0.2s;
}
#reg #verifmdp #oopsi
{
  display:block;
  font-size:2.35vh;
  text-decoration:underline;
  margin-bottom:6vh;
}
#mdpconfhide
{
  display:none;
  width:100%;
  height:100vh;
  background:rgba(0,0,0,.8);
  position:fixed;
  left:0;
  top:0;
  opacity:0;
  z-index:2;

}
#inserror
{
  display:block;
  position:absolute;
  width:100%;
  text-align:center;
  color:red;
  top:70vh;
  z-index:1;
}
#coerror
{
  color:red;
  margin-top:7vh;
  opacity:0;
}
@media only screen and (max-width : 900px) {
  h1
  {
    font-family: "Folloween" !important;
    color:white;
    font-size:10vh;
    margin-top:2.5vh;
  }
  #filmbg
  {
    display:inline-block;
    width:90%;
    margin-top:11vh;
    line-height:3vh;
  }
}




//////// style

.cta__primary {
  display: inline-block;
  margin: 0 auto 0 auto;
  background-color: #ae0008;
  padding: 8px 30px;
  border-radius: .5vh;
  border: solid 2px #ae0008;
}
