.loader_text {
    position: absolute;
    color: rgba(0, 0, 0, .3);
    font-size: 2.2em;
    top: 55%;
    left: 50%;
    white-space: nowrap;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    transition: transform;
    font-family: var(--logo-Font);
}
.loader_text:before {
    content: attr(data-text);
    position: absolute;
    overflow: hidden;
    max-width: 8em;
    white-space: nowrap;
    color: var(--mainRed);
    animation: loading 2s infinite linear;
    font-family: var(--logo-Font);
}

@keyframes loading {
    0% {
        max-width: 0;
    }
}

.loader_container{
    width: 100vw;
    height: 100vh;
    position: absolute;
    opacity: 1;
    transition: opacity 250ms ease-in-out;
}
.loader_container.is_hidden{
    opacity: 0;
    transition: opacity 250ms ease-in-out;
}
.loader_container.is_none{
    display: none;
}

#ween_loader{

    height: 7vw;
    position: absolute;
    top: 43%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    transition: transform 500ms ease-in-out;
    animation: floatingGhost infinite 3s ease-in-out;
}
.ween_container{

    position: relative;
    width: 100%;
    height: 100%;
}

@keyframes floatingGhost {

    0%{
        transform: translate(-50%,-45%);
    }
    50%{
        transform: translate(-50%,-55%);
    }
    100%{
        transform: translate(-50%,-45%);
    }


}


@media screen and (max-width: 630px) {
    #ween_loader{

        height: 20vw;
        
    }
}