/* ======================================

Waiting for global harmonization :
font-size is put here, on HTML tag.
After it will be necessary to put it inside
__variable.css or reset.css

============== FOLLOWEEN ============== */

html {
  font-size: 62.5%;
}

body.chatbot {
  background-color: var(--pureBlack);
  font-family: 'Roboto', sans-serif;
  color: white;
  height: 100vh;
  font-size: var(--S-Font);
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: background 500ms ease-in-out;
  margin-top: 0;

  b {
    display: inline;
    font-weight: bold;
  }

  @font-face {
    font-family: "Folloween";
    src: url('../../fonts/Folloween.ttf');
  }
  #logomenu a {
    font-family: "Folloween";
    font-size: var(--logo-Size);
    color: var(--mainRed);
  }

  .container {
    width: 80%;
    height: 100%;
    display: flex;
    justify-content: center;
    /*padding: 0% 0 5% 0;*/
    align-items: center;
    flex-direction: column;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  .container.is_active {
    transition: opacity 250ms ease-in-out;
    opacity: 1;
  }

  .chatbot_avatar {
    display: inline-block;
    width: 7vw;
    height: 7vw;
    border-radius: 50%;
    vertical-align: middle;
    position: relative;
  }

  .chatbot_avatar img {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    transition: transform 500ms ease-in-out;
    animation: floatingGhost infinite 3s ease-in-out;
  }

  @keyframes floatingGhost {

    0% {
      transform: translate(-50%, -45%);
    }
    50% {
      transform: translate(-50%, -55%);
    }
    100% {
      transform: translate(-50%, -45%);
    }


  }

  .chatbot_ween {
    display: block;
    justify-content: center;
    align-items: center;
    height: 35%;
  }

  .chatbot_message {
    display: inline-block;
    vertical-align: middle;
    width: 60vw;
    padding: 30px 50px;
    text-align: center;
    line-height: 2.8vh;
    border-radius: 50px;
    background-color: white;
    color: black;
    margin-left: 30px;
    font-size: var(--L-Font);
  }

  .chatbot_answers {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: row;
  }

  .chatbot_answers a {
    display: inline-flex;
    text-decoration: none;
    color: white;
    padding: 2vh 3.3vw 2vh 3.3vw;
    border-radius: 4vh;
    background: var(--gradiantRedReverse);
    transition: all .3s;
    margin-left: 2vw;
    margin-right: 2vw;
    border: solid 2px var(--sndRed);

  }

  .chatbot_answers a:hover {
    border-color: white;

  }

  .year_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -125%);
  }

  .two_input {
    width: 75%;
    position: relative;
    display: block;
    height: 10vh;
    text-align: left;
  }

  .range_bg {
    display: block;
    width: 100%;
    height: 2vh;
    background: var(--mainRed);
    opacity: 0.2;
    position: absolute;
    top: 2px;
    z-index: 0;
    left: 2px;
    border-radius: 10px;
  }

  .range_interval {
    display: block;
    width: 100%;
    height: 2vh;
    background: var(--gradiantRedReverse);
    position: absolute;
    top: 2px;
    z-index: 1;
  }

  .years_range {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 2;
    -webkit-appearance: none;
    outline: 0;
    padding: 0;
    border-radius: 10px;
  }

  .years_range::-webkit-slider-thumb {
    pointer-events: all;
    position: relative;
    outline: 0;
    z-index: 9999 !important;
    background: white !important;
    height: 4vh;
    -webkit-appearance: none;
    width: 4vh;
    border: none;
    border-radius: 50px;
    margin-top: -1vh;
    outline: 0;
  }

  .years_range::-moz-range-thumb {
    pointer-events: all;
    position: relative;
    outline: 0;
    z-index: 9999 !important;
    background: white !important;
    height: 4vh;
    -webkit-appearance: none;
    width: 4vh;
    border: none;
    border-radius: 50px;
    margin-top: -1vh;
    outline: 0;
  }

  .years_range::-ms-thumb {
    pointer-events: all;
    position: relative;
    outline: 0;
    z-index: 9999 !important;
    background: white !important;
    height: 4vh;
    -webkit-appearance: none;
    width: 4vh;
    border: none;
    border-radius: 50px;
    margin-top: -1vh;
    outline: 0;
  }

  .years_range::-webkit-slider-runnable-track {
    display: block;
    height: 0;
    background-color: #eee;
    margin: 0;
    outline: 0;
    position: relative;
    z-index: 1;
    border-radius: 10px;
  }

  .years_range::-moz-range-track {
    display: block;
    height: 0;
    background-color: #eee;
    margin: 0;
    outline: 0;
    position: relative;
    z-index: 1;
    border-radius: 10px;
  }

  .years_range::-ms-track {
    display: block;
    height: 0;
    background-color: #eee;
    margin: 0;
    outline: 0;
    position: relative;
    z-index: 1;
    border-radius: 10px;
  }

  .chatbot_nav {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 5% 0;
  }

  .chatbot_ariane {
    position: relative;
    display: inline-flex;
    width: 90%;
    height: 7px;
    justify-content: left;
  }

  .chatbot_barre {
    height: 100%;
    background-color: #ae0008;
    width: 0;
    border-radius: 10px;
    transition: width 250ms ease;
  }

  .chatbot_back {
    display: inline-flex;
    margin: auto;
    transition: all 0.3s;
    color: var(--mainRed);
  }

  .chatbot_back i {
    padding-right: 10px;
  }

  .chatbot_back:hover {
    opacity: .7;
  }

  .chatbot_back img {
    width: 100%;
  }

  .movie_found {
    cursor: pointer;
    text-decoration: underline;
    color: var(--mainRed);
    border-radius: 7px;
    transition: color 0.1s;
  }

  .movie_found:hover {
    color: #888;
  }

  article {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  article.showed {
    display: inline-block !important;
    position: absolute;
    z-index: 1;
    margin: 0;
    opacity: 1;
    transition: opacity 500ms ease-in-out;
  }

  .hidder {
    opacity: 0;
    transition: opacity 500ms ease-in-out;
  }

  .hidder.on {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.9);
    opacity: 1;
    transition: opacity 500ms ease-in-out;
  }

  @media screen and (max-width: 1050px) {

    .chatbot_avatar {
      display: block;
      margin: auto;
      width: 10vw;
      top: -15%;
    }
    .two_input {
      height: 85px;
    }
    .container {
      justify-content: space-around;
    }
    .chatbot_nav {
      padding: 0;
    }
    .chatbot_ween {
      height: 30%;
    }
  }
  @media screen and (max-width: 630px) {


    .container {
      justify-content: space-around;
    }
    .chatbot_ween {
      margin: 10px;
      height: auto;
    }
    .chatbot_answers {
      flex-direction: column;
    }
    .chatbot_answers a {
      margin: 10px;
    }
    .chatbot_avatar {
      top: -5%;
      width: 7vw;
    }
    .chatbot_avatar img {
      width: 200%;
    }
    .chatbot_message {
      margin: 20px;
      font-size: var(--M-Font);
      padding: 15px 40px;
    }
    #logomenu {
      margin-top: 15px;
    }
    #logomenu a {
      font-size: 40px;
    }
    .font_title_white {
      font-size: 30px;
    }
    .year_container {

      -webkit-transform: translate(-50%, -200%);
      -moz-transform: translate(-50%, -200%);
      -ms-transform: translate(-50%, -200%);
      -o-transform: translate(-50%, -200%);
      transform: translate(-50%, -200%);
    }
    .two_input {
      height: 20px;
    }
  }
}

