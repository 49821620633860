html
{
	overflow:visible;
	background-color:#000;
}
body
{
	overflow:visible;
}
@font-face {
	font-family: "Icons";
	src: url('../../css/icons.ttf');
}
#iconrefresh {
    font-family: Icons;
    display: inline;
    font-size: 1.75vh;
    font-weight: bold;
}
.refresh
{
	text-decoration:none;
	border-bottom: solid white 0.1vh;
	margin-top:4vh;
	margin-bottom:8vh;
	display:inline-block;
}
.refresh:hover
{
	border-color:#bababa;
}
.failsearch
{
	margin-top: 20vh;
    margin-bottom: 30vh;
    font-size: 2.7vh;
}
#filmbg
{
	margin-bottom:0;
	margin-top: 30px;
}

@media only screen and (max-device-width : 750px) and (orientation : portrait){
	.failsearch {
	    margin-top: 9vh;
	    margin-bottom: 15vh;
	    font-size: 2.15vh;
	    line-height:3vh;
	}
}
