#containfiches, .showfilms, #artcontainer, #resultcontent {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;


  #addfilmmin {
    display: none;
  }
}

.movie__card_wrapper {
  display: inline-block;
  width: 270px;
  height: 300px;
  position: relative;
  padding: 0 30px 20px 30px;
  border-radius: 15px;
  box-shadow: 0vh 0vh 3vh rgba(255, 255, 255, .08);
  background-color: rgba(0, 0, 0, .8);
  margin: 0 15px 30px 15px;
  vertical-align: top;
  cursor: pointer;
  border: solid 1px rgba(#AE0008, 0);
  transition: all ease-in-out .3s;

  @media screen and (max-width: 640px) {
    width: 270px;
  }

  &:hover {
    border: solid 1px rgba(#AE0008, 0.2);
  }

  &.active, &.showed {
    height: 580px;
    border: solid 1px rgba(#AE0008, 0.2);

    @media screen and (max-width: 640px) {
      height: 560px;
    }

    .movie__card_secondary {
      display: block;
    }

    #infossec {
      position: relative;
      margin-top: 20px;
      padding: 0;
      bottom: inherit;
    }

    .movie__card_main {
      margin-top: 230px;
    }
  }

  &.hidden {
    opacity: .3;
  }
}

.movie__card_main {
  margin-top: 230px;

  .movie__card_title {
    position: relative;
  }
}

.movie__card_secondary {
  display: none;
}

#nblike {
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  width: 100%;
  left: 0;
  background-color: rgba(0,0,0,.35);
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

#nblike img {
  width: 1.8vh;
}

#filmpict {
  width: 100%;
  height: 215px;
  left: 0;
  top: 0;
  position: absolute;
  background: url("../../img/films/1.jpg");
  background-size: cover;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2vh;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

h2 {
  display: block;
  text-align: left;
  font-size: 1.6rem;
  position: relative;
}

#infosprim {
  margin-top: .3vh;
  text-align: left;
  font-style: italic;
  font-size: 1.4rem;
}

#infossec {
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  display: flex;
  position: absolute;
  box-sizing: border-box;
  padding: 0 28px;
  left: 0;
  bottom: 15px;

  div:last-child {
    display: flex;
    align-items: center;
  }
}

#infossec p {
  display: inline-block;
  margin-right: 1vh;
}

#infossec img {
  width: 2rem;
  display: inline-block;
}

#genres {
  margin-left: 1vh;
}

#jumpt {
  margin-left: 3vh;
}

.mylike img {
  width: 15px;
  padding: 1px 2px 0;
}

.mylike {
  display: inline-block;
  margin-left: 0.25vh;
  padding-left: 0.3vh;
  padding-right: 0.3vh;
  padding-top: 0.5vh;
  border-radius: 400vh;
  border: dashed 0.2vh rgba(255, 255, 255, .0);
  position: absolute;
  right: -10px;
  top: -6px;
}

.mylike:hover {
  transition: 0.3s;
  border: dashed 0.2vh rgba(255, 255, 255, .8);
}

#synopt {
  margin-top: 2.5vh;
  text-align: left;
}

#synopc {
  text-align: left;
  height: 130px;
  line-height: 2.45vh;
  padding: 15px 0 25px;
  font-size: 1.5rem;
  position: relative;

  @media screen and (max-width: 640px) {
    height: 125px;
  }
}

.fichelink {
  text-decoration: none;
  display: inline-block;
  width: 45px;
  height: 45px;
  margin: 15px;
}

.fichelink img {
  height: 3.6vh;
  width: 3.6vh;
  display: inline-block;
  border: solid 0.4vh rgba(0, 0, 0, 0);
  border-radius: 500vh;
  padding-left: 0.6vh;
  padding-right: 0.6vh;
  padding-top: 0.5vh;
  padding-bottom: 0.5vh;
  /*filter: saturate(40%);*/
  transition: 0.3s;
}

.fichelink:hover > img {
  border: solid 0.4vh #aaa;
}

.linkgrey img {
  filter: saturate(0%);
}

.linkgrey:hover > img {
  border: solid 0.4vh rgba(0, 0, 0, 0);
}

.lasuite {
  color: #aaa;
  text-decoration: none;
}

.lasuite:hover {
  color: #fff;
}

.avufalse {
  display: inline-block;
  margin: 0 auto 0;
  background-color: #ae0008;
  padding: 8px 30px;
  border-radius: .5vh;
  border: solid 2px #ae0008;
}

.movie__card_addList, .movie__card_removeList {
  visibility: hidden;
  opacity: 0;
  background-color: rgba(0, 0, 0, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all .5s ease-in-out;
  position: absolute;
  height: 160px;
  width: 210px;
  z-index: 5;

  p {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

.avufalse:hover {
  color: var(--mainRed);
  background-color: black;
}

.avutrue{
  opacity:.2;
  background-color: #ae0008;
  padding: 8px 30px;
  color: #fff;
}
.avutrue:hover{
  color:#fff;
}

#addfilmmin {
  display: none;
}

.need_to_connect{
  display:flex;
  position:fixed;
  left:0;
  top:0;
  width:100vw;
  height:100vh;
  background:rgba(0,0,0,.9);
  opacity:0;
  transition:all .3s;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  z-index: 1;
}
.need_to_connect div{
  display: block;
  background-color: #111;
  padding: 30px 50px;
  border-radius: 1vh;
}
.need_to_connect div a{
  display: inline-block;
  margin: 20px auto 0 auto;
  background-color: #ae0008;
  padding: 8px 30px;
  border-radius: .5vh;
  border: solid 2px #ae0008;
}
.need_to_connect div a:hover{
  background: inherit;
  color: white;
}
.need_to_connect div a:active{
  background-color: #222;
}
@media screen and (max-width: 768px) {
  .need_to_connect div{
    width: 90%;
    padding: 60px 2%;
  }
  .need_to_connect div p{
    line-height: 3vh;
    margin-bottom: 1vh;
  }
}

