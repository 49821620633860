html
{
	overflow:visible;
	background-color:#000;
}
body.checker
{
	overflow:visible;
	height:100%;

  #moduledesc
  {
    padding-top: 4vh;
    line-height: 3vh;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 2vh;
  }
  .comtype{
    margin-bottom:8vh;
    margin-left: 5vh;
    margin-right: 5vh;
    line-height: 3vh;
  }

  .membertitles {
    font-weight: bold;
    margin-bottom: 4vh;
  }

  .allmembers
  {
    width: 80vw;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .show_profiles{
    display:block;
    width:100%;
    transition:all .3s;
  }

  #filmbg
  {
    margin-bottom:5vh;
  }
  .failsearch
  {
    margin-top: 20vh;
    margin-bottom: 30vh;
    font-size: 2.7vh;
  }
  .videquest {
    font-size: 2.7vh;
    margin-top: 20vh;
    margin-bottom: 25vh;
    margin-left: 5vw;
    line-height: 4vh;
    margin-right: 5vw;

  }
  .continvisible
  {
    opacity:0;
  }
  .name_search{
    display:block;
    text-align:center;
    width:100%;
    margin-bottom:3vh;
  }
  .name_search input{
    display:inline-block;
    width:40vh;
    height: 5vh;
    padding: 0;
  }
  .name_search input:focus{
    background-color: #cccccc;
    transition: 0.2s;
  }
  .name_search a{
    height:5vh;
    width:6vh;
    display:inline-flex;
    background-color:#AAA;
    vertical-align: top;
    justify-content: center;
    align-items: center;
    margin-left: -0.53vh;
    /*margin-bottom:3px;*/
  }
  .name_search a:hover{
    background-color:#999;
  }
  .name_search a img{
    width:65%;
    height:65%;
  }
  .count_profiles{
    font-size: 1.9vh;
    margin-top: 0.2vh;
    opacity:0;
    transition:opacity 0.3s;
  }

  @media only screen and (max-width : 750px) {
    .allmembers
    {
      width: 95%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
    .videquest {
      font-size: 2.7vh;
      margin-top: 13vh;
      margin-bottom: 18vh;
      margin-left: 5vw;
      line-height: 4vh;
      margin-right: 5vw;
    }
    .continvisible
    {
      display:none;
    }
  }
  @media only screen and (min-device-width : 751px) and (max-device-width : 1100px) and (orientation : landscape) {
    .allmembers
    {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media only screen and (min-device-width : 1300px) and (max-device-width : 1400px) and (min-device-height : 1000px) and (orientation : landscape) {
    .allmembers
    {
      width: 100%;
      display: block;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media only screen and (max-device-width : 750px) {
    .failsearch {
      margin-top: 9vh;
      margin-bottom: 3vh;
      font-size: 2.15vh;
      line-height:3vh
    }
  }
}
