footer{
  width: 100%;
  padding: 1vw 0vw 1vw 0vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #111111;
  margin-top: 7vh;
  flex-direction: column;
  position: relative;
  bottom: 0;

}

.content{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;

}
.rs{
  display: flex;
  flex:1;
  justify-content: center;

}
.logo{
  font-family: "Folloween" !important;
}
.facebook{
  margin: 0vw 2vw 0vw 2vw;
  font-size: 2vw;
}

.twitter{
  margin: 0vw 2vw 0vw 2vw;
  font-size: 2vw;
}

.youtube{
  margin: 0vw 2vw 0vw 2vw;
}

footer p{
  font-size: small;
  margin: 10px 0;

}
.logo p{
  font-size: 3vh;
}
.nav{
  display: flex;
  flex:4;
  justify-content: center;
}
.nav ul li a{


  border-radius: 50%;
  padding-left: 0;
  padding-right: 0;
  vertical-align: 0;
  margin-left: 0;
  margin-right: 0;
  box-shadow: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  width: 30px;
  height: 30px;
  background-color: var(--mainRed);

}
.nav ul li a i{
  color: var(--pureBlack);
}
.nav ul{
  margin-top: 0;
  background-color: transparent;
  display:flex;
}

.nav ul li{

  margin: 10px;
  width: 30px;
  height: 30px;
  border: solid 1px var(--mainRed);
  border-radius: 50%;
  background-color: var(--mainRed);

}
.tip_wrapper{
  position: absolute;
  background-color: #d84758;
  height: 60px;
  width: 60px;
  right: 5%;
  border-radius: 50%;
  top: 0%;
  transform: translate(-50%,-50%);
  background-image: url(../../img/tipeee.png);
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.tip_wrapper a {
  width: 100%;
  height: 100%;
  display: block;
}
.redletter{
  color:var(--mainRed);
}
.lastline{
  margin: 10px 10px 0 10px;
  line-height: 3vh;
}
