ul {
    display: block;
}

body {
    margin-top: 110px;
}
.overlay {
    display: none;
}
.overlay.active {
    width: 100vw;
    display: block;
    height: 100vh;
    background-color: rgba(0, 0, 0, .7);
    position: fixed;
    top: 0;
    z-index: 5;
}

.nav_container {
    display: flex;
    align-items: center;
    padding: 10px 80px 0;
    box-sizing: border-box;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    transition: background-color ease-in-out .2s;
    z-index: 15;
}

.nav_container.sticky {
    background-color: var(--pureBlack);
    position: fixed;
    height: 70px;
    padding-top: 10px;
    padding-bottom: 10px;
}

.nav_container::after {
    content: '';
    display: block;
    position: absolute;
    top: 70px;
    left: 0;
    width: 0;
    height: 1px;
    background-color: var(--mainRed);
    transition: width ease-in-out .3s;
}

.nav_container.sticky::after {
    width: 100%;
}

.nav_container__menulist {
    display: inline-block;
}

.nav_container__menulist li {
    display: inline-block;
    margin: 0 30px;
    vertical-align: middle;
}

.nav_container__menulist li:first-child a p {
    display: inline-block;
    margin-left: 10px;

    @media only screen and (max-width: 992px) {
        margin-left: 0;
    }
}

.nav_container__connexion svg {
    width: 30px;
    margin-right: 10px;
}

.nav_container__connexion a {
    display: flex;
    align-items: center;
}

.nav_container__logo, .nav_container__menulist, .nav_container__connexion {
    display: inline-block;
}

.nav_container__menulist {
    flex: 1;
}

#logout {
    display: block;
    text-decoration: none;
    text-align: center;
    margin-top: 2.5vh;
    font-size: 1.7vh;
}

#logomenu {
    color: #ae0008;
    font-family: "Folloween" !important;
    font-size: 30px;
}

#logomenu p {
    margin-top: 0.7vh;
    display: inline-block;
}

#logomenu span {
    color: #FFFFFF;
}

.weenbot {

}

.weenbot a {
    position: relative;
    display: flex;
    align-items: center;
}

.weenbot img {
    width: 20px;
    margin: 5px;
}

.mobile_menu {
    display: none;
}

button.mobile_menu {
    margin-top: 2.5vh;
    cursor: pointer;
    height: 5vh;
    text-decoration: none;
    background-color: rgba(255, 255, 255, .14);
    border-radius: 1vh;
    padding-left: 3vh;
    padding-right: 3vh;
    height: 7vh;
    vertical-align: top;
    margin-left: 1vw;
    margin-right: 1vw;
    box-shadow: 0vh 0.2vh 0.5vh rgba(0, 0, 0, .4);
    border: 0;
    color: white;
    width: 48vh;
    font-size: 100%
}

button.mobile_menu img {
    height: 17%;
    transform: rotate(90deg);
    margin-left: 1vh;
    transition: 0.3s;
}

button.turnon img {
    transform: rotate(180deg);
}

@media only screen and (max-width: 1224px) {
    .nav_container {
        padding: 10px 20px;
    }

    .nav_container__connexion span {
        display: none;
    }
}

@media only screen and (max-width: 992px) {
    .nav_container {
        padding: 10px 30px;
        justify-content: space-between;
    }

    .nav_container__logo.active {
        opacity: 0.3;
    }

    .nav_container__connexion {
        z-index: 1;
    }

    .nav_container__connexion svg {
        margin-right: 0;
    }

    .nav_container__menulist {
        margin-right: 10px;
        transition: all ease .3s;
    }

    .nav_container__menulist.active {
        z-index: 1;
    }

    .nav_container__menulist.active ul {
        width: 250px;
        visibility: visible;
    }

    .nav_container__menulist {
        margin-left: auto;
        margin-right: 20px;
        flex: inherit;
        position: absolute;
        right: 60px;
    }

    .nav_container__menulist li {
        white-space: nowrap;
        margin: 25px;
        margin-left: 40px;
    }

    .nav_container__menulist ul {
        width: 0;
        transition: all ease .3s;
        visibility: hidden;
        position: absolute;
        height: 100vh;
        background-color: var(--pureBlack);
        padding-top: 100px;
        right: -80px;
        top: -28px;
        text-align: left;
        flex-direction: column;
        display: flex;
    }

    .nav_container__menulist::after {
        content: '';
        background-image: url(../../img/account/menu.svg);
        background-position: center;
        background-size: cover;
        transition: all .3s ease-in-out;
        display: inline-block;
        width: 20px;
        height: 17px;
        position: relative;
        z-index: 20;
    }
    .nav_container__menulist.active::after {
        content: '';
        margin-right: 180px;
        width: 20px;
        height: 20px;
        background-image: url(../../img/account/cross.svg);
    }

}
