body{
  overflow: initial;
}
.page_login{
  display: flex;
  justify-content: center;
  align-items: center;
  height:500px;
}
.page_login form{
  width: 300px;
}
.pan_login{
  display:block;
}
.pan_left{
  width:40%;
  border-right:solid 2px white;
}
.page_login button{
  background: inherit;
  font-family: inherit;
  font-size: inherit;
  color: #ae0008;
  border: 0;
  cursor: pointer;
}
.page_login button:focus{
  border:0;
  outline:0;
}
.page_login #inserror{
  position:static;
  opacity:0;
}
.page_login #coerror, .page_login #inserror{
  margin-top: 2vh;
}
.page_login {
  h3 {
    width: 100%;
    margin: 20px auto;
    font-size: 2rem;
  }
}
.page_login .pan_register{
  display:none;
}
.page_login article{
  padding:0 6%;
  transition:opacity .15s;
}
.page_login h4{
  font-size: 3vh;
  line-height: 4vh;
  margin-bottom: 13vh;
}
.pan_left a:hover span{
  background-color:#26467f;
  transition:.2s;
}
.fbcolor{
  color:white !important;
  display: inline-block;
  text-decoration: none;
  font-family: 'Mukta Mahee', sans-serif;
  background-color: #3c5a9a;
  padding-left: 3vh;
  padding-right: 3vh;
  padding-top: .5vh;
  padding-bottom: 0.5vh;
  font-weight: bold;
  border-radius: 0.5vh;
  margin: 0 5px;
}
.pan_register #inscfin{
  display: block;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  opacity: 1;
  border-radius: 0;
}
.page_login .pan_register, .page_login .pan_login {
  background-color: #111;
  border-radius: 13px;
  padding: 2% 3%;
  margin: 0 3%;
}

@media (max-width: 1024px) {
  .pan_left{
    display:none;
  }
  .page_login{
    height:auto;
  }
  .page_login button{
    display: block;
    margin: 5px auto;
  }
  .pan_register #inscfin {
    width:100%;
  }
}


@media only screen and (max-device-width: 900px) {

  .page_login .pan_login, .page_login .pan_register {
    background-color: #111;
    border-radius: 13px;
    padding: 5% 7%;
    margin: 0 3%;
  }
}
