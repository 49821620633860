.svg-filters {
    position: absolute;
    visibility: hidden;
    width: 1px;
    height: 1px; }

.button--1, .button--1 .button__bg, .button--2, .button--4, .button--4 .button__bg, .button--5, .button--6, .button--7, .button--8, .button--9, .button--10 {
    -webkit-font-smoothing: antialiased;
    background-color: #222;
    border: none;
    display: inline-block;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.85em;
    font-weight: 700;
    text-decoration: none;
    user-select: none;
    letter-spacing: 0.1em;
    color: white;
    padding: 20px 40px;
    text-transform: uppercase;
    -webkit-transition: background-color 0.1s ease-out;
    -moz-transition: background-color 0.1s ease-out;
    transition: background-color 0.1s ease-out; }
.button--1:hover, .button--1 .button__bg:hover, .button--2:hover, .button--4:hover, .button--4 .button__bg:hover, .button--5:hover, .button--6:hover, .button--7:hover, .button--8:hover, .button--9:hover, .button--10:hover {
    background-color: #2CD892;
    color: #fff; }
.button--1:focus, .button--1 .button__bg:focus, .button--2:focus, .button--4:focus, .button--4 .button__bg:focus, .button--5:focus, .button--6:focus, .button--7:focus, .button--8:focus, .button--9:focus, .button--10:focus {
    outline: none;
    color: #fff; }

.safari .button, .safari
.button__bg {
    -webkit-filter: none !important;
    filter: none !important; }

.button--1 {
    position: relative;
    background: none;
    z-index: 0; }
.button--1:hover {
    background: none; }
.button--1:hover .circle {
    background: #2CD892; }
.button--1:hover .button__bg {
    background: #2CD892; }
.button--1 .button__container {
    position: absolute;
    display: block;
    width: 200%;
    height: 400%;
    top: -150%;
    left: -50%;
    pointer-events: none;
    z-index: -1;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out; }
.button--1 .button__bg, .button--1 .circle {
    -webkit-animation: hue-rotate 10s linear infinite;
    -moz-animation: hue-rotate 10s linear infinite;
    animation: hue-rotate 10s linear infinite; }
.button--1 .button__bg {
    position: absolute;
    width: 50%;
    height: 25%;
    top: 50%;
    left: 25%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
.button--1 .circle {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background: #222;
    -webkit-transition: background-color 0.1s ease-out;
    -moz-transition: background-color 0.1s ease-out;
    transition: background-color 0.1s ease-out; }
.button--1 .circle.top-left {
    top: 40%;
    left: 27%; }
.button--1 .circle.bottom-right {
    bottom: 40%;
    right: 27%; }

.button--2 {
    outline: 90px solid transparent !important;
    position: relative;
    z-index: 0;
    background-color: transparent;
    filter: url('#filter-goo-2')
    }
.button--2 .left, .button--2 .right {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 15px;
    background: #222;
    -webkit-transition: background 0.1s ease-out;
    -moz-transition: background 0.1s ease-out;
    transition: background 0.1s ease-out;
    top: 50%;
    margin-top: -12px;
    z-index: -2; }
.button--2 .left.left, .button--2 .right.left {
    left: 0; }
.button--2 .left.right, .button--2 .right.right {
    right: 0; }
.button--2 .button__bg {
    content: "";
    background: #222;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transition: background 0.1s ease-out;
    -moz-transition: background 0.1s ease-out;
    transition: background 0.1s ease-out; }
.button--2:hover {
    background-color: transparent; }
.button--2:hover:before, .button--2:hover span {
    background-color: #2CD892; }

.button--4 {
    position: relative;
    z-index: 1;
    width: 80px;
    height: 80px;
    line-height: 80px;
    padding: 0;
    background: none; }
.button--4:hover {
    background: none; }
.button--4:focus {
    outline: none; }
.button--4:active .button__bg {
    transform: scale(0.8); }
.button--4 .button__bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    outline: 90px solid transparent !important;
    padding: 0;
    border-radius: 100px;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    background: none;
    font-weight: bold;
    z-index: -2;
    -webkit-transition: all 0.1s ease-out;
    -moz-transition: all 0.1s ease-out;
    transition: all 0.1s ease-out; }
.button--4 .button__bg:hover {
    background: none; }
.button--4 .blob {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    border-radius: 70px;
    opacity: 0.3;
    background: #222;
    -webkit-transition: background 0.1s ease-out;
    -moz-transition: background 0.1s ease-out;
    transition: background 0.1s ease-out;
    z-index: -1;
    pointer-events: none; }
.button--4 .blob:first-child {
    opacity: 1; }
.button--4 .blob:nth-child(n+2) {
    width: 100%;
    height: 100%; }

.button--5 {
    transform: translateZ(0);
    outline: 90px solid transparent !important; }

.button--6 {
    transform: translateZ(0);
    outline: 90px solid transparent !important; }

.button--7 {
    transform: translateZ(0);
    outline: 90px solid transparent !important; }

.button--8 {
    position: relative;
    z-index: 1;
    outline: 10px solid #F6F6F6 !important;
    background: #F6F6F6;
    border: 2px solid #000;
    color: #000; }
.button--8:focus {
    color: #222; }
.button--8:hover {
    background: #F6F6F6;
    border-color: #2CD892;
    color: #2CD892; }

.button--9 {
    -webkit-transition: all 0.45s ease-out;
    -moz-transition: all 0.45s ease-out;
    transition: all 0.45s ease-out;
    background-image: -webkit-radial-gradient(60% 20%, circle, #FF6023 0%, #DA305D);
    background-image: radial-gradient(  circle at 60% 20%, #FF6023 0%, #DA305D);
    box-shadow: 0 0 0 20px #f6f6f6;
    border-radius: 80px;
    color: #FFF;
    width: 80px;
    height: 80px;
    line-height: 80px;
    padding: 0; }
.button--9:focus {
    color: #FFF; }
.button--9:hover {
    background-image: -webkit-radial-gradient(60% 20%, circle, #FF6023 0%, #DA305D);
    background-image: radial-gradient(  circle at 60% 20%, #FF6023 0%, #DA305D);
    color: #FFF; }

.button--10 {
    position: relative;
    background: none;
    z-index: 0;
    width: 135px; }
.button--10:hover {
    background: none; }
.button--10:hover .button__bg {
    background: #2CD892; }
.button--10 .button__bg {
    transform: translateZ(0);
    outline: 90px solid transparent !important;
    background: #222;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    -webkit-transition: background 0.1s ease-out;
    -moz-transition: background 0.1s ease-out;
    transition: background 0.1s ease-out;
    border-radius: 40px; }

@keyframes hue-rotate {
    from {
        -webkit-filter: hue-rotate(0);
        -moz-filter: hue-rotate(0);
        -ms-filter: hue-rotate(0);
        filter: hue-rotate(0); }
    to {
        -webkit-filter: hue-rotate(360deg);
        -moz-filter: hue-rotate(360deg);
        -ms-filter: hue-rotate(360deg);
        filter: hue-rotate(360deg); } }