body.calendrier {
padding-bottom: 50px;
}

h1{
	font-size:9vh;
}
.red_letter{
	color:#ae0008;
}
.namesite{
	font-family: Folloween;
	font-size:2.5vh;
	margin-left: 5px;
}
.cal_des{
	max-width: 85%;
	line-height: 2.8vh;
	margin: 2.6vh auto 4vh auto;
}
.calendrier{
	width:125vh;
	margin: auto;
	display: flex;

	align-items: center;
	flex-direction: column;
}
.calendrier .waiting_days{
	display:flex;
	justify-content: center;
	align-items: center;
	width:100%;
	flex-wrap: wrap;
}
.waiting_days li{
	display:flex;
	background-color:black;
	width:calc(100vh / 5 - 4vh);
	height:calc(100vh / 5 - 4vh);
	margin:2vh;
	box-shadow:0vh 0vh 3vh rgba(255, 255, 255, .4);
	justify-content: center;
	align-items: center;
	font-size:5vh;
	position: relative;
	color:#666;
	transition:0.4s;
}
.waiting_days .day_past .numero, .waiting_days .day_activ .numero, .special_day.day_activ .numero{
	transition:0.2s;
}
.special_day.day_activ .numero{
	cursor: pointer;
}
.waiting_days .day_past, .sepcial_day .day_past{
	color:white;
	cursor:pointer;
}
.waiting_days .day_activ, .special_day.day_activ{
	color:#ae0008;
	cursor:pointer;
}
.special_day.day_activ{
	cursor:default;
}
.followcard{
	cursor:pointer;
}
.waiting_days .day_past .numero:hover, .waiting_days .day_activ .numero:hover, .special_day.day_past .numero:hover, .special_day.day_activ .numero:hover{
	color:white;
	background-color:#ae0008;
}
.waiting_days li .el, .special_day .el{
	position:absolute;
	left:0;
	top:0;
	width:100%;
	height:100%;
	display:flex;
}
.waiting_days li .numero, .special_day .numero{
	background-color:black;
	justify-content: center;
	align-items: center;
	z-index:2;
}
.waiting_days li .pic, .special_day .pic{
	background-color:black;
	background-size:cover;
	z-index:1;
}
.special_day{
	width: calc((100vh / 5 - 4vh) * 6 + 5 * 4vh);
	height:calc(100vh / 5 - 4vh);
	display:flex;
	background-color:black;
	box-shadow:0vh 0vh 3vh rgba(255, 255, 255, .4);
	justify-content: center;
	align-items: center;
	font-size:5vh;
	position: relative;
	color:#666;
	transition:0.4s;
	margin:2vh 0 2vh 0;
	transition:box-shadow 0s;
}
.special_day ul{
	display:flex;
	justify-content: space-between;
	align-items: center;
}
.special_day ul li{
	display:flex;
	width:calc(100vh / 5 - 4vh);
	height:calc(100vh / 5 - 4vh);
}
.cal_credits{
	margin: 12.5vh auto 2vh auto;
	opacity: 0.5;
	transition:0.5s;
}
.sep_bdp{
	border: 0;
	height: 0.2vh;
	background-color: white;
	opacity: 0.5;
	width: 25vh;
	margin-bottom: 0;
	transition:0.5s;
}
.credit_div{
	display:inline-block;
}
.credit_div:hover > *{
	opacity:1;
}
.infos_window{
	position:fixed;
	background: rgba(0,0,0,.9);
	transition: opacity .2s;
	width:100vw;
	top:0;
	left:0;
	z-index: 4;
	display:none;
	opacity:0;
}
.infos_window .infos_conteneur{
	width:100%;
	display:flex;
	flex-direction: column;
}
.infos_window div{
	width:100%;
}
.infos_window .infos_s1{
	height:100vh;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition:margin-top 0.5s;
}
.infos_window .infos_s2{
	height: 100vh;
	padding: 5vh 0;
}
.infos_window .infos_picture{
	width: 25vh;
	height: 25vh;
	margin-bottom: 5vh;
	display:flex;
	justify-content: center;
	align-items: center;
}
.infos_window .infos_picture span{
	background-size:cover;
	background-color:black;
	box-shadow:0vh 0vh 3vh rgba(255, 255, 255, .4);
	width:25%;
	height:25%;
	transition:all .3s;
}
.infos_window .infos_s1 h2{
	font-size:4vh;
}
.go_to_bottom{
	display: block;
	background-color: #ae0008;
	width: 25vh;
	border-radius: 1vh;
	padding: 1.5vh 0;
}
.go_to_bottom:hover{
	background-color:#6c0106;
	color:white;
}
.infos_window .infos_s1 h3{
	margin-bottom:20vh;
}
.special_window{
	position:fixed;
	background: rgba(0,0,0,.9);
	transition: opacity .2s;
	width:100vw;
	height:100vh;
	top:0;
	left:0;
	z-index: 4;
	display:flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	display:none;
	opacity:0;
}
.special_window h2{
	font-family: Folloween;
	font-size: 9vh;
	color: #FFA52B;
	margin-bottom: 2vh;
}
.special_window .special_conteneur{
	width:100%;
	height:100%;
	display:none;
	opacity:0;
	transition:opacity 0.2s;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.special_window img{
	position: absolute;
	height: 25vh;
	transition:height 3s, opacity .3s;
	z-index:5;
	opacity:.9;
}
#confetti{
	height: 100%;
	left: 0px;
	position: fixed;
	top: 0px;
	width: 100%;
	z-index: -1;
}
.special_conteneur p{
	width: 95vh;
	line-height: 2.8vh;
}
.back_folloween{
	text-decoration: none;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	margin-top:5vh;
}

@media only screen and (max-width: 768px){
	.infos_window .infos_s2{
		padding: 3vh 0;
	}
	.special_conteneur p{
		width: 90%;
	}
}
@media (orientation: portrait) {
	.special_window h2{
		font-size: 15vw;
		margin-bottom: 6vh;
	}
}

@media (orientation: portrait) {
	.back_folloween{
		margin-top:2vh;
	}
	.calendrier {
		width: 100vw
	}
	.waiting_days li{
		margin: .5vh;
	}
	.special_day{
		width: calc((100vh / 5 - 4vh) * 3 + 1 * 2vh);
		height: calc(((100vh / 5 - 4vh) * 2) + 1vh);
		margin:.5vh 0 .5vh 0;
	}
	.special_day .el{
		flex-wrap: wrap;
	}
	.special_day ul li{
		margin: 0px 0 1vh 0;
	}
}
@media only screen
and (device-width : 375px)
and (device-height : 812px)
and (-webkit-device-pixel-ratio : 3) {

	.calendrier {
		width: calc(3*(100vh / 5 - 6vh) + 3vh);
	}
	.special_day{
		width: calc((100vh / 5 - 6vh) * 3 + 1 * 2vh);
		height: calc(((100vh / 5 - 6vh) * 2) + 1vh);
		margin:.5vh 0 .5vh 0;
	}
	.waiting_days li{
		width: calc(100vh / 5 - 6vh);
		height: calc(100vh / 5 - 6vh);
	}
	.special_day ul li {
		display: flex;
		width: calc(100vh / 5 - 6vh);
		height: calc(100vh / 5 - 6vh);
	}

}
