.fichemembre
{
	display:inline-block;
	vertical-align: top;
}
.membrecontain
{
	display:inline-block;
	border-radius:1vh;
	box-shadow: 0vh 0vh 3vh rgba(255, 255, 255, .4);
	background-color:rgba(0, 0, 0, .8);
	padding-left: 2vh;
    padding-top: 2vh;
    padding-right: 2vh;
    padding-bottom:2vh;
    width: 50vh;
    margin-left: 1.5vh;
    margin-right: 1.5vh;
    margin-top: 2vh;
    text-align: left;
}

.show_profiles:last-child .membrecontain:nth-child(1) .mleft:after{
	content: '';
	background-image: url(../../img/first-medal.png);
	position: absolute;
	width: 50px;
	height: 50px;
	bottom: 3px;
	left: -25px;
	transform: rotate(-10deg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.show_profiles:last-child .membrecontain:nth-child(2) .mleft:after{
	content: '';
	background-image: url(../../img/second-medal.png);
	position: absolute;
	width: 50px;
	height: 50px;
	bottom: 3px;
	left: -25px;
	transform: rotate(-10deg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
.show_profiles:last-child .membrecontain:nth-child(3) .mleft:after{
	content: '';
	background-image: url(../../img/third-medal.png);
	position: absolute;
	width: 50px;
	height: 50px;
	bottom: 3px;
	left: -25px;
	transform: rotate(-10deg);
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}

.mleft
{
	display:inline-block;
	width: 12vh;
	vertical-align: middle;
	text-decoration: none;
	position: relative;
}
.avatar
{
	display: inline-block;
	width: 12vh;
	height: 12vh;
	font-size: 6vh;
	text-align:center;
	text-decoration: none;
	margin-bottom: 1vh;
	transition:0.3s;
}
.avatar p
{
	margin-top: 2.9vh;
	text-decoration: normal;
}
.minfos
{
	display:inline-block;
	padding-left:2vh;
	vertical-align:middle;
}
.mpseudo {
    display: block;
    font-size: 2.5vh;
    margin-bottom: 2vh;
    font-weight:bold;
}
.titlescore
{
	display: block;
    font-size: 1.8vh;
    margin-bottom: 0.5vh;
}
.mscore
{
	    display: block;
    font-size: 4vh;
    margin-left: 0vh;
    margin-bottom: 2vh;
}
.followfalse
{
	color: black;
	display: block;
	background-color: #efefef;
	/* margin-top: 7vh; */
	text-decoration: none;
	padding-top: 1.2vh;
	padding-bottom: 1.2vh;

	width: 12vh;
	/* margin-left: 5vh; */
	text-align: center;
}
.followfalse:hover
{
	color:black;
	background-color:#bbb;
}
.followtrue
{
	    color: black;
    display: block;
    background-color: #efefef;
    /* margin-top: 7vh; */
    text-decoration: none;
    padding-top: 1.2vh;
    padding-bottom: 1.2vh;

    opacity:0.2;
    width: 12vh;
    /* margin-left: 5vh; */
    text-align: center;
}
.followtrue:hover
{
	color:black;
}

#stop_follow{
	opacity:0;
}

@media only screen and (max-width : 750px) {

	.membrecontain {
	    display: inline-block;
	    border-radius: 1vh;
	    box-shadow: 0vh 0vh 3vh rgba(255, 255, 255, .4);
	    background-color: rgba(0, 0, 0, .8);
	    padding-left: 2vh;
	    padding-top: 2vh;
	    padding-right: 2vh;
	    padding-bottom: 2vh;
	    width: 84%;
	    margin-left: 1.5vh;
	    margin-right: 1.5vh;
	    margin-top: 2vh;
	    text-align: left;
	}
}
@media only screen and (max-width : 380px) and (min-height:810px) {
		.minfos {
    display: inline-block;
    /* padding-left: 2vh; */
    vertical-align: middle;
    display: block;
    margin-top: 3vh;
    margin-bottom: 1vh;
}
.membrecontain
{
	text-align:center;
	}
}
