html {
  overflow: visible;
  background-color: #000;
}

input[type="text"], input[type="submit"], input[type="password"], input[type="number"]{
  padding: 10px 0;
}

#searchtype, .comtype {
  display: inline-block;
  margin-left: 5vw;
  margin-right: 5vw;
  padding: 10px;
  border-radius: 15px;

  a {
    display: inline-block;
    position: relative;
    width: 35px;
    height: 35px;
    transition: all ease-in-out .5s;
    opacity: .2;

    span {
      position: absolute;
      top: 40px;
      left: -6px;
    }
  }

  .touch, .write, .medal, .cluster {
    background-image: url("../../img/touch.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    span {
      font-size: 1.6rem;
    }
  }

  .write {
    background-image: url("../../img/write.svg");
    margin-left: 30px;
  }

  .medal {
    background-image: url("../../img/medal.svg");
    margin-left: 50px;

    span {
      left: -14px;
    }
  }

  .cluster {
    background-image: url("../../img/cluster.svg");

    span {
      left: -14px;
    }
  }

  .changesearch.active, .changecom.active {
    opacity: 1;
  }
}

body.target-search {
  overflow: visible;
  height: 100%;

  .checkdv {
    display: inline-block;
    width: auto;
    height: auto;
    margin-bottom: 0;
    margin-top: 10vh;
  }

  #searchdesc {
    margin-top: 4vh;
    margin-bottom: 2vh;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  h3 {
    width: auto;
    line-height: 3.3vh;
    font-size: 3vh;
    margin-left: 5vw;
    margin-right: 5vw;
    margin-bottom: 1vh;
    margin-top: 9vh;
    text-align: center;
  }

  .listradio a {
    display: inline-block;
    background-color: rgba(255, 255, 255, .14);
    width: 105px;
    height: 105px;
    margin-left: 0.5vw;
    margin-right: 0.5vw;
    vertical-align: middle;
    font-size: 1.6vh;
    margin-bottom: 0.5vh;
    margin-top: 0.5vh;
  }

  .listradio a li img {
    height: 45.9px;
    margin-top: 15.9px;
  }

  .listradio a li p {
    margin-top: 12px;
  }

  .streamradio:hover {
    color: white;
  }

  .jumpradio:hover {
    color: white;
  }

  .genreradio:hover {
    color: white;
  }


  .launchsearch {
    margin-top: 5vh;
  }

  .launchsearch:hover {
    background-color: #bababa;
    transition: 0.2s;
  }

  #filmbg {
    margin-bottom: 5vh;
  }

  .deuxform {
    margin-top: 9vh;
    width: 90vw;
  }

  .deuxform p {
    margin-bottom: 11vh;
  }

  .deuxform .champtext {
    display: block;
    margin-top: 2vh;
  }

  .deuxform .champtext:focus {
    background-color: #cccccc;
    transition: 0.2s;
  }

  #champtitle {
    width: 51vw;
    margin-bottom: 9vh;
    margin-left: auto;
    margin-right: auto;
  }

  #champreal {
    width: 26vw;
    margin-bottom: 4vh;
  }

  #champdate {
    width: 20.5vw;
  }

  .deuxform .launchsearch {
    width: 320px;
    margin-left: auto;
    margin-right: auto;
  }

  #lcb {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }

  #lcb #divreal {
    display: inline-block;
    margin-right: 1.5vw;

  }

  #lcb #divdate {
    display: inline-block;
    //margin-left: 1.5vw;
  }

  .labelclick {
    text-decoration: none;
  }

  .labelclick:hover {
    color: white;
  }

  @media only screen and (min-width: 1950px) {
    .listradio a {
      display: inline-block;
      background-color: rgba(255, 255, 255, .14);
      width: 8vw;
      height: 8vw;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
      vertical-align: middle;
      font-size: 1.6vh;
      margin-bottom: 0.5vh;
      margin-top: 0.5vh;
    }
    .listradio a li img {
      height: 3.49vw;
      margin-top: 1.21vw;
    }
  }

  @media only screen and (min-device-width: 1300px) and (max-device-width: 1400px) and (min-device-height: 1000px) and (orientation: landscape) {
    .listradio a {
      display: inline-block;
      background-color: rgba(255, 255, 255, .14);
      width: 150px;
      height: 150px;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
      vertical-align: middle;
      font-size: 1.6vh;
      margin-bottom: 0.5vh;
      margin-top: 0.5vh;
    }
    .listradio a li img {
      height: 65.625px;
      margin-top: 18px;
    }

  }

  @media only screen and (min-device-width: 500px) and (max-device-width: 1100px) and (min-device-height: 700px) and (orientation: landscape) {
    .listradio a {
      display: inline-block;
      background-color: rgba(255, 255, 255, .14);
      width: 12vw;
      height: 12vw;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
      vertical-align: middle;
      font-size: 1.6vh;
      margin-bottom: 0.5vh;
      margin-top: 0.5vh;
    }
    .listradio a li img {
      height: 5vw;
      margin-top: 1.8vw;
    }

  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1400px) and (orientation: portrait) {
    .listradio a {
      display: inline-block;
      background-color: rgba(255, 255, 255, .14);
      width: 15vw;
      height: 15vw;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
      vertical-align: middle;
      font-size: 1.6vh;
      margin-bottom: 0.5vh;
      margin-top: 0.5vh;
    }
    .listradio a li img {
      height: 6.5vw;
      margin-top: 1.8vw;
    }
    #lcb #divreal {
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 70vw;

    }
    #lcb #divdate {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 70vw;
    }
    #champtitle {
      width: 70vw;
      margin-bottom: 5vh;
      margin-left: auto;
      margin-right: auto;
    }
    #champreal {
      width: 70vw;
      margin-bottom: 5vh;
    }
    #champdate {
      width: 70vw;
    }
    .deuxform .launchsearch {
      width: 70vw;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media only screen and (max-device-width: 767px) and (orientation: portrait) {
    #lcb #divreal {
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 90vw;

    }
    #lcb #divdate {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 90vw;
    }
    #champtitle {
      width: 90vw;
      margin-bottom: 5vh;
      margin-left: auto;
      margin-right: auto;
    }
    #champreal {
      width: 90vw;
      margin-bottom: 5vh;
    }
    #champdate {
      width: 90vw;
    }
    .deuxform .launchsearch {
      width: 90vw;
      margin-left: auto;
      margin-right: auto;
    }
    .listradio a {
      display: inline-block;
      background-color: rgba(255, 255, 255, .14);
      width: 27vw;
      height: 27vw;
      margin-left: 0.5vw;
      margin-right: 0.5vw;
      vertical-align: middle;
      font-size: 1.6vh;
      margin-bottom: 0.5vh;
      margin-top: 0.5vh;
    }
    .listradio a li img {
      height: 12vw;
      margin-top: 3.8vw;
    }
  }

  @media only screen and (max-width: 900px) {
    #lcb #divreal {
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 70vw;

    }
    #lcb #divdate {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 70vw;
    }
    #champtitle {
      width: 70vw;
      margin-bottom: 5vh;
      margin-left: auto;
      margin-right: auto;
    }
    #champreal {
      width: 70vw;
      margin-bottom: 5vh;
    }
    #champdate {
      width: 70vw;
    }
    .deuxform .launchsearch {
      width: 70vw;
      margin-left: auto;
      margin-right: auto;
    }
  }

  @media only screen and (max-device-width: 900px) {
    #lcb #divreal {
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 100%;

    }
    #lcb #divdate {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
    #champtitle {
      width: 100%;
      margin-bottom: 5vh;
      margin-left: auto;
      margin-right: auto;
    }
    #champreal {
      width: 100%;
      margin-bottom: 5vh;
    }
    #champdate {
      width: 100%;
    }
    .deuxform .launchsearch {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
}


