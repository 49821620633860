@import url('https://fonts.googleapis.com/css?family=Permanent+Marker|Roboto');

:root{

    --mainRed : #AE0008;
    --sndRed : #DB242C;
    --gradiantRed : linear-gradient(-180deg, #AE0008 0%, #DB242C 60%);
    --gradiantRedReverse :linear-gradient(-90deg, #AE0008 0%, #DB242C 60%);

    --pureBlack : #111111;
    --softBlack : #333333;

    --softGrey : #2d2d2c;

    --mainFont : 'Roboto', sans-serif;
    --logo-Font : 'Folloween';

    --XS-Font : 1rem;
    --S-Font : 1.2rem;
    --M-Font : 1.4rem;
    --L-Font : 1.6rem;

    --logo-Size : 7rem;



}

.red_highlight{
    color: var(--mainRed);
}
.font_title_white{
    color: white;
    font-size: 50px;


}
