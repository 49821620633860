html
{
	overflow:visible;
	background-color:#000;

}
body.questionnaire
{
	overflow:visible;

	#filmbg
	{
		display:none;
	}
	h3
	{
		font-size:6vh;
		text-align:center;
		margin-top:7vh;
		width:auto;
		display:block;
		margin-bottom:7vh;

	}
	#justif
	{
		text-align:left;
		margin-left:3vw;
		margin-right:3vw;
		line-height:3vh;
	}
	h4
	{
		font-size: 3vh;
		text-align: left;
		font-weight: bold;
		margin-left: 7vw;
		margin-top: 7vh;

	}
	form
	{
		width:100%;
	}
	input:not(.submit)
	{
		display:inline-block;
		width: 2vw;
		height: 2.3vh;
		margin-left:10vw;
		margin-right:2.5vw;
		margin-top:2.5vh;
		border-radius:500px;
	}
	label
	{
		font-size:2.3vh;
	}
	.quest
	{
		font-size:2.4vh;
		display:block;
		text-align:left;
		margin-left:7vw;
		margin-top:1.2vh;
		margin-bottom:2vh;
	}
	div
	{
		text-align:left;
	}
	.submit
	{
		display:none;
		width:40vh;
		margin-left:auto;
		margin-right:auto;
		margin-top:10vh;
		display:none;
	}
	.submit:hover
	{
		background-color:#bababa;
		transition:0.2s;
	}
	a:not(.labelclick)
	{
		display:block;
		position:absolute;
		bottom:5vh;
		text-align:center;
		width:100%;
	}
	.labelclick
	{
		text-decoration:none;
	}
	.labelclick:hover
	{
		color:white;
	}
}

@media screen and (max-width: 768px) {
	body.questionnaire {
		margin-top: 0;
		padding: 0 20px;

		h3 {
			font-size: 2.2rem;
			margin: 25px 0;
		}

		.labelclick {
			display: block;
		}

		.quest {
			text-align: center;
			color: var(--mainRed);
			margin-left: 0;
			font-size: 2rem;
		}

		h4 {
			text-align: center;
			color: var(--mainRed);
			margin-left: 0;
			margin-top: 30px;
			font-size: 2rem;
		}

		#firstq {
			text-align: center;
			padding: 0 0 20px;
		}

		label {
			font-size: 1.6rem;
		}

		input:not(.submit) {
			display: block;
			margin: 10px auto;
			height: 25px;
			width: 25px;
			margin-top: 30px;
		}

		input[type="button"] {
			padding: 10px 0;
		}

		a:not(.labelclick) {
			position: relative;
			bottom: 0;
			font-style: italic;
			text-decoration: underline;
			opacity: .5;
			padding-bottom: 30px;
		}
	}

}
