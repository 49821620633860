
html {
  overflow: visible;
  background-color: #000;

}

body {
  overflow: visible;
}

#containfiches {
  justify-content: center;
}

#containfiches h3 {
  width: 90%;
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 25px;
  margin-top: 35px;
  font-weight: bold;
  line-height: normal;
}

.cont_a {
  width: 100%;
  margin-top: 5px;
}

.nbfilms {
  width: 90%;
  margin: auto;
}

.main_content {
  max-width: 1400px;
  margin: auto;

  #intropres {
    line-height: 23px;
    margin: 30px;
  }

  b {
    font-weight: bold;
  }

}

