
html {
  overflow: visible;
  background-color: #000;
}

body.random {
  overflow: visible;

  #moduledesc {
    padding-top: 4vh;
    line-height: 3vh;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-bottom: 8vh;
  }

  #machinelaunch {
    color: #000;
    text-decoration: none;
    background-color: #efefef;
    font-size: 1.5rem;
    padding: 10px 20px;
    width: 160px;
    border-radius: .5vh;
    display: inline-block;
    margin-bottom: 10vh;
  }

  #machinelaunch:hover {
    background-color: #bbb;
  }

  #filmbg {
    margin-top: 7vh;
    margin-bottom: 5vh;
  }

  #artcontainer {
    transition: 0.5s;
    opacity: 0;
  }
}
